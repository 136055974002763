'use strict';

var countryToStateMapping = window.IRO_CONFIG.preferences.countryToStateMapping;
/**
 * Initialize state field
 * @param {Object} $form - the parent form of the country field (shipping|billing|address book)
 * @param {string} countryCodeSelected - selected country code
 */
function initializeAuxiliaryFields($form, countryCodeSelected) {
    if (countryToStateMapping) {
        var $stateField = $form.find('[name$=_stateCode]');
        var $stateFieldWrapper = $stateField.closest('.form-group');
        if (countryToStateMapping[countryCodeSelected]) {
            $stateFieldWrapper.removeClass('d-none');
            $stateFieldWrapper.addClass('required');
            $.ajax({
                url: $stateField.data('url'),
                type: 'get',
                dataType: 'json',
                data: {
                    countryCode: countryCodeSelected
                },
                success: function (data) {
                    $stateField.empty();
                    data.stateCode.options.forEach(option => {
                        $stateField.append(`<option id="${option.id}" value="${option.htmlValue}" >${option.label}</option>`);
                    });
                }
            });
        } else {
            $stateField.val('');
            $stateField.prop('required', false);
            $stateFieldWrapper.addClass('d-none');
            $stateFieldWrapper.removeClass('required');
        }
    }
}

module.exports = {
    methods: {
        initializeAuxiliaryFields: initializeAuxiliaryFields
    },
    initialize: function () {
        $('.country-js').on('change', function () {
            var $form = $(this).closest('form');
            var countryCodeSelected = $(this).val();

            initializeAuxiliaryFields($form, countryCodeSelected);
        });
    }
};
