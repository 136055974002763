'use strict';

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearFormErrors(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
    $(form).find('.input-group.is-invalid').removeClass('is-invalid');
}

/**
 * Smothy scrolling to field with error
 * @param {element} fieldSelector - scroll to this fieldSelector with error
 */
function scrollToField(fieldSelector) {
    var field = $(fieldSelector).get(0);
    if (field) {
        field.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
}

module.exports = function (formElement, payload) {
    // clear form validation first
    clearFormErrors(formElement);
    $('.alert', formElement).remove();

    if (typeof payload === 'object' && payload.fields) {
        Object.keys(payload.fields).forEach(function (key) {
            if (payload.fields[key]) {
                let $input = $(formElement).find('[name="' + key + '"]');

                // Validate the new password input
                if ($input.closest('.custom-password').find('.input-group')) {
                    var feedbackElementPassword = $input
                    .closest('.custom-password')
                    .children('.invalid-feedback');

                    if (feedbackElementPassword.length > 0) {
                        if (Array.isArray(payload[key])) {
                            feedbackElementPassword.html(payload.fields[key].join('<br/>'));
                        } else {
                            feedbackElementPassword.html(payload.fields[key]);
                        }
                        feedbackElementPassword.closest('.custom-password').find('.input-group').addClass('is-invalid');
                        feedbackElementPassword.closest('.custom-password').find('.form-control').addClass('is-invalid');
                    }
                } else {
                    var feedbackElement = $(formElement).find('[name="' + key + '"]')
                    .parent()
                    .children('.invalid-feedback');

                    if (feedbackElement.length > 0) {
                        if (Array.isArray(payload[key])) {
                            feedbackElement.html(payload.fields[key].join('<br/>'));
                        } else {
                            feedbackElement.html(payload.fields[key]);
                        }
                        feedbackElement.siblings('.form-control').addClass('is-invalid');
                        scrollToField(feedbackElement);
                    }
                }
            }
        });
    }
    if (payload && payload.error) {
        var form = $(formElement).prop('tagName') === 'FORM'
            ? $(formElement)
            : $(formElement).parents('form');

        form.prepend('<div class="alert alert-danger" role="alert">'
            + payload.error.join('<br/>') + '</div>');
    }
};
